<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";

import {mapState} from "vuex";


export default {
  page: {
    title: "Billing",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Layout,
    PaymentApprove: () => import('@/components/lazy/billing/PaymentApprove'),
  },
  validations: {

  },
  methods: {

  },
  created() {

  },
  data() {
    return {

    }
  }
};
</script>

<template>
  <div class="body">
    <div>
      <div id="layout-wrapper" layout="horizontal">
        <div class="main-content" style="margin: unset !important;">
          <div class="page-content min-vh-100">
            <PaymentApprove/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>